<template>
  <b-card
      no-body
      class="mb-0 "
  >
    <titulo-card-com-voltar
        voltar="projetos"
        titulo="Lista de Comentários do Projeto"
    />
    <b-card
        cols="12"
        class=" px-2"
    >
      <b-form-group
          label="Novo Comentário"
          label-for="comentario"
          class="mb-2"
      >
        <quill-editor
            id="comentario"
            v-model="itemData.comentario"
            :options="snowOption"
        />

        <div class="d-flex justify-content-end mt-1">
          <b-button
              variant="success"
              @click="enviarComentario"
          >
            <feather-icon
                icon="SendIcon"
                class="mr-50"
            />
            <span class="align-middle">Enviar</span>
          </b-button>
        </div>

      </b-form-group>
      <b-row>
        <b-col
            cols="12"
            class="mb-1"
        >
          <h4 v-if="listaComentarios.length">
            Comentários do projeto: {{ listaComentarios[0].comentavel.descricao }}
          </h4>
        </b-col>
        <b-col
            v-for="(item, index) in listaComentarios"
            :key="item.id"
            cols="12"
        >
          <b-media
              no-body
              class="border p-2 mb-1 rounded"
          >
            <b-media-aside>

              <b-avatar
                  v-if="item.usuario"
                  size="50"
                  :src="item.usuario.imagem"
                  :text="avatarText(item.usuario.name)"
              />

            </b-media-aside>
            <b-media-body>
              <div class="d-flex justify-content-between pb-2">
                <div>
                  <h6>
                    <span class="font-weight-bolder">{{ item.usuario && item.usuario.name }} </span> <span
                      v-if="item.usuario && item.usuario.funcao"
                      class="font-weight-light"
                  >[{{ item.usuario && item.usuario.funcao }}]</span>
                  </h6>
                  <small>{{ mascaraDataBr(item.created_at) }}</small>
                </div>
                <div
                    v-if="$can('excluir', 'comentarios' )"
                    class="d-flex justify-content-end"
                >
                  <b-button
                      variant="link"

                      size="sm"
                      @click="modalExcluir(item)"
                  >
                    <feather-icon
                        icon="TrashIcon"
                        size="16"
                        class="text-danger"
                    />
                  </b-button>
                </div>
              </div>

              <b-card-text
                  class="bg-light rounded p-1 font-size-9 descricao-comentario"
                  v-html="processarComentario(item.comentario)"
              />


            </b-media-body>
          </b-media>

        </b-col>
      </b-row>
    </b-card>
    <b-modal
        id="imagem-modal"
        size="lg"
        hide-footer
    >
      <img :src="imagemModalSrc" class="img-fluid"/>
    </b-modal>
    <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir o comentário do usuário"
        text-secondary="Essa ação não poderá ser desfeita."
        :item-id="dadosItem.id"
        :item-nome="dadosItem.nome"
        @itemConfirmado="excluirItem"
    />
  </b-card>
</template>

<script>
import { avatarText, mascaraDataBr } from '@core/utils/filter'
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { useToast } from 'vue-toastification/composition'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import router from '@/router'
import store from '@/store'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import userStoreModule from '../comentarioStoreModule'

export default {
  components: {
    BCardText,
    BMediaBody,
    BMediaAside,
    BMedia,
    BAvatar,
    TituloCardComVoltar,
    BFormGroup,
    BCol,
    BCard,
    quillEditor,
    BButton,
    BRow,
    modalConfirmacao,
  },
  data() {
    return {
      imagemModalSrc: '',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getComentarios()
      },
    },
  },
  updated() {
    setTimeout(() => {
      const imagensComentario = document.querySelectorAll('.img__comentario')
      imagensComentario.forEach((img) => {
        if (!img.hasAttribute('data-click-listener')) {
          img.addEventListener('click', (event) => {
            this.exibirImagem(event.target)
          })
          img.setAttribute('data-click-listener', 'true')
        }
      })
    }, 3000)
  },
  methods: {
    mascaraDataBr,
    avatarText,

    modalExcluir(item) {
      this.dadosItem.nome = item.usuario.name
      this.dadosItem.id = item.id.toString()

      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },

    processarComentario(comentario) {
      const div = document.createElement('div')
      div.innerHTML = comentario

      const imagens = div.querySelectorAll('img')
      imagens.forEach((img) => {
        img.style.width = '100px'
        img.style.height = '80px'
        img.style.cursor = 'pointer'
        img.classList.add('img__comentario')
        img.id = `img-${Math.floor(10000 + Math.random() * 90000)}`

      })

      return div.innerHTML
    },
    exibirImagem(target) {
      this.imagemModalSrc = target.src
      this.$bvModal.show('imagem-modal')
    }
  },
  setup() {
    const toast = useToast()
    const itemData = ref({ comentario: '' })
    const dadosItem = ref({
      nome: '',
      id: '',
    })
    const listaComentarios = ref([])

    const snowOption = {
      placeholder: 'Insira seu comentário aqui...',
      modules: {},
      theme: 'snow',
    }

    const COMENTARIOS_APP_STORE_MODULE_NAME = 'app-comentarios'

    // Register module
    if (!store.hasModule(COMENTARIOS_APP_STORE_MODULE_NAME)) store.registerModule(COMENTARIOS_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMENTARIOS_APP_STORE_MODULE_NAME)) store.unregisterModule(COMENTARIOS_APP_STORE_MODULE_NAME)
    })

    const getComentarios = () => {
      store.dispatch('app-comentarios/fetchComentarios', { id: router.currentRoute.params.id })
          .then(response => {
            listaComentarios.value = response.data.reverse()
          })
          .catch(error => {
            if (error.response.status === 404) {
              listaComentarios.value = undefined
            }
          })
    }
    getComentarios()

    const enviarComentario = () => {
      if (itemData.value.comentario === '' || itemData.value.comentario == null) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Comentário não pode ser vazio',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

      store.dispatch('app-comentarios/store', {
        id: router.currentRoute.params.id,
        comentario: itemData.value.comentario,
      })
          .then(() => {
            itemData.value = { comentario: '' }
            listaComentarios.value = []
            getComentarios()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Comentários incluído no projeto',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
    }
    const excluirItem = ({ id }) => {
      store.dispatch('app-comentarios/delete', {
        id,
        projeto: router.currentRoute.params.id,
      })
          .then(() => {
            listaComentarios.value = []
            getComentarios()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Comentário excluído com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
    }

    return {
      itemData,
      snowOption,
      enviarComentario,
      listaComentarios,
      dadosItem,
      excluirItem,
      getComentarios,

    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.text-gray-200 {
  color: #d7d7d7;
}

.descricao-comentario * {
  width: 100%;
}
</style>
